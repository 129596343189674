<template>
    <main>
        <Player v-if="isFetched" id="player-vod" ref="pvod" :sources="sources" text="Profesor" :auto-refresh="false" />
    </main>
</template>

<script>
import { env_config } from "../../config";
import { enterFalseFullScreen, leavePlayersPage } from "../utils/players";
import Player from "../components/Player.vue";
import axios from "axios";

export default {
    components: {
        Player
    },

    data() {
        return {
            sources: [],
            isFetched: false
        };
    },

    created() {
        enterFalseFullScreen();

        // let response = await axios.get(`http://localhost:8001/vods/${this.$route.params.id}/${this.$route.params.vod}`);
        axios.get(`${env_config.API_BASE_DOMAIN}/vods/${this.$route.params.id}/${this.$route.params.vod}`).then((res) => {
            const uris = res.data.uris;
            for (let i = 0; i < uris.length; i++) {
                this.sources.push({ type: "hls", file: `https://vod.${env_config.WEBRTC_ROOT}/${uris[i]}` });
            }
            this.isFetched = true;
        });
    },

    mounted() {
        if (this.isFetched) {
            const chatButtons = document.querySelectorAll("button.op-button.op-chat-button");
            chatButtons[0].style.display = "none";
        }

        const previousCamButton = document.querySelector("button.op-button.op-previous-cam-button");
        const nextCamButton = document.querySelector("button.op-button.op-next-cam-button");

        document.body.addEventListener("click", (event) => {
            if (previousCamButton.contains(event.target) || nextCamButton.contains(event.target)) {
                let currentSource = this.$refs.pvod.getCurrentSource();
                const camText = document.querySelector(".op-watermark span.op-watermark-text");
                switch (currentSource) {
                    case 0:
                        camText.innerHTML = "Profesor";
                        break;
                    case 1:
                        camText.innerHTML = "Pizarra";
                        break;
                    case 2:
                        camText.innerHTML = "Presentación";
                        break;
                }
            }
        });
    },

    beforeUnmount() {
        leavePlayersPage();
    }
};
</script>
